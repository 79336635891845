import querystring from 'querystring';

import assets from '__generated__/assets.json';
import { getConfig } from 'shared/getEnv';
import { Country } from 'config/country';

type ImageKey = keyof typeof assets.image;
export type IconKey = keyof typeof assets.icon;
type ImageParams = {
  w?: number | string;
  width?: number | string;
  h?: number | string;
  height?: number | string;
  convert?: 'webp' | 'jpeg' | 'png';
};
type Option = ImageParams & {
  dynamic?: boolean;
  baseUrl?: string;
};

const { publicRuntimeConfig: config } = getConfig();
const country: Country = config.country ?? 'id';
const cdnUrl = config.assetPrefix ? `${config.assetPrefix}/public` : '';

const defaultParams: ImageParams = {};

function makeCdnUrl(image: string, options: Option = {}) {
  const { dynamic, baseUrl, ...params } = options;

  if (dynamic || !cdnUrl) {
    return image;
  }

  if (baseUrl) {
    return `${baseUrl}${image}`;
  }

  const query = querystring.encode({ ...defaultParams, ...params });
  return `${cdnUrl}${image}?${query}`;
}

function getAsset(
  image?: string | Partial<Record<Country, string>>,
  options?: Option,
): string {
  if (typeof image === 'string') {
    return cdnUrl ? makeCdnUrl(image, options) : image;
  }

  if (image === undefined || image[country] === undefined) {
    throw Error(`Asset for ${country} is not found`);
  }

  return getAsset(image[country], options);
}

const images = (key: ImageKey, options?: Option) => {
  const image = assets.image[key];

  return getAsset(image, options);
};

const icons = (key: IconKey, options?: Option) => {
  const image = assets.icon[key];

  return getAsset(image, options);
};

const formatImgixUrl = (url: string, params: ImageParams = {}) => {
  try {
    const uri = new URL(url);
    const query = querystring.encode({ ...params });

    return `${uri.origin}${uri.pathname}?${query}`;
  } catch (error) {
    return url;
  }
};

export type { ImageKey };
export { icons, images, formatImgixUrl, getAsset };

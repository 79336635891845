
// --- Code generated by protoc-gen-rg. DO NOT EDIT. --- //
import * as t from 'runtypes';
import { left, right, Either } from "fp-ts/lib/Either";
import {
  FetcherContext,
  Error as APIErrorResponse,
} from '@rogu/fetch/dist/base';
import fetcher from '@/shared/fetcher';
import { ADT } from '@rogu/adt';






export type DecodeError = t.ValidationError;

export type { APIErrorResponse };

export type ErrorResponse = DecodeError | APIErrorResponse | Error | undefined;

	export const AutocompleteParamSchema = t.Union(
      t.Literal("AUTOCOMPLETE_PARAM_UNKNOWN"),
      t.Literal("AUTOCOMPLETE_PARAM_OWNER"),
      t.Literal("AUTOCOMPLETE_PARAM_TAG"),
      t.Literal("AUTOCOMPLETE_PARAM_EVENT"),
      t.Literal("AUTOCOMPLETE_PARAM_FEATURE"),
      t.Literal("AUTOCOMPLETE_PARAM_PRODUCT"),
      t.Literal("AUTOCOMPLETE_PARAM_ACTION"),
  );

  export type AutocompleteParam = t.Static<typeof AutocompleteParamSchema>;

	export const BatchStatusSchema = t.Union(
      t.Literal("BATCH_WAITING_FOR_APPROVAL"),
      t.Literal("BATCH_REJECTED"),
      t.Literal("BATCH_APPROVED"),
  );

  export type BatchStatus = t.Static<typeof BatchStatusSchema>;

	export const CountrySchema = t.Union(
      t.Literal("ID"),
      t.Literal("TH"),
      t.Literal("VN"),
  );

  export type Country = t.Static<typeof CountrySchema>;

	export const DeployVariantSchema = t.Union(
      t.Literal("STAGING"),
      t.Literal("PRODUCTION"),
  );

  export type DeployVariant = t.Static<typeof DeployVariantSchema>;

	export const DeployVariantFilterSchema = t.Union(
      t.Literal("All"),
      t.Literal("STAG"),
      t.Literal("PROD"),
  );

  export type DeployVariantFilter = t.Static<typeof DeployVariantFilterSchema>;

	export const DeploymentStatusSchema = t.Union(
      t.Literal("DEPLOYMENT_STATUS_SUCCESS"),
      t.Literal("DEPLOYMENT_STATUS_FAILED"),
      t.Literal("DEPLOYMENT_STATUS_IN_PROGRESS"),
  );

  export type DeploymentStatus = t.Static<typeof DeploymentStatusSchema>;

	export const DeploymentTypeSchema = t.Union(
      t.Literal("DATA_QUALITY_CHECK"),
      t.Literal("CLOUD_BUILD"),
  );

  export type DeploymentType = t.Static<typeof DeploymentTypeSchema>;

	export const ETLConfigTypesSchema = t.Union(
      t.Literal("CONFIG_TYPE_VIEW"),
      t.Literal("CONFIG_TYPE_ETL"),
  );

  export type ETLConfigTypes = t.Static<typeof ETLConfigTypesSchema>;

	export const FilterLegacyMappingSchema = t.Union(
      t.Literal("FILTER_LEGACY_MAPPING_ALL"),
      t.Literal("FILTER_LEGACY_MAPPING_TRUE"),
      t.Literal("FILTER_LEGACY_MAPPING_FALSE"),
  );

  export type FilterLegacyMapping = t.Static<typeof FilterLegacyMappingSchema>;

	export const IsLearningSchema = t.Union(
      t.Literal("IsLearningFalse"),
      t.Literal("IsLearningTrue"),
  );

  export type IsLearning = t.Static<typeof IsLearningSchema>;

	export const LastCheckedStatusSchema = t.Union(
      t.Literal("STATUS_UNKNOWN"),
      t.Literal("PENDING"),
      t.Literal("QUEUED"),
      t.Literal("WORKING"),
      t.Literal("SUCCESS"),
      t.Literal("FAILURE"),
      t.Literal("INTERNAL_ERROR"),
      t.Literal("TIMEOUT"),
      t.Literal("CANCELLED"),
      t.Literal("EXPIRED"),
  );

  export type LastCheckedStatus = t.Static<typeof LastCheckedStatusSchema>;

	export const ProposalActionSchema = t.Union(
      t.Literal("APPROVE"),
      t.Literal("REJECT"),
      t.Literal("COMMENT"),
  );

  export type ProposalAction = t.Static<typeof ProposalActionSchema>;

	export const ProposalStatusSchema = t.Union(
      t.Literal("WAITING_FOR_APPROVAL"),
      t.Literal("SCHEMA_APPROVED"),
      t.Literal("DEPLOY_STAGING_ONGOING"),
      t.Literal("IN_STAGING"),
      t.Literal("DEPLOY_STAGING_FAILED"),
      t.Literal("DEPLOY_PROD_ONGOING"),
      t.Literal("READY_FOR_PROD"),
      t.Literal("IN_PRODUCTION"),
      t.Literal("DEPLOY_PROD_FAILED"),
      t.Literal("CLOSED"),
      t.Literal("ALL_STATUS"),
      t.Literal("WAITING_ON_QUEUE_STAGING"),
      t.Literal("WAITING_ON_QUEUE_PRODUCTION"),
      t.Literal("NOT_CREATED_YET"),
  );

  export type ProposalStatus = t.Static<typeof ProposalStatusSchema>;

	export const SchemaTypeSchema = t.Union(
      t.Literal("SCHEMA_FULL"),
      t.Literal("SCHEMA_DEADLETTER"),
  );

  export type SchemaType = t.Static<typeof SchemaTypeSchema>;
  
  
  
	export const GetETLConfigGeneratorRequestSchema = t.Record({
				uuid:
				t.String
				,
		})

	export type GetETLConfigGeneratorRequest = t.Static<typeof GetETLConfigGeneratorRequestSchema>;
  
  
  
  
	export const GetETLConfigGeneratorResponseSchema = t.Record({
				yaml:
				t.String
				,
		})

	export type GetETLConfigGeneratorResponse = t.Static<typeof GetETLConfigGeneratorResponseSchema>;
  
  
  
  
	export const ETLConfigGeneratorResponseSchema = t.Record({
				uuid:
				t.String
				,
		})

	export type ETLConfigGeneratorResponse = t.Static<typeof ETLConfigGeneratorResponseSchema>;
  
  
  
  
	export const CommonCreateOrEditResponseSchema = t.Record({
				status:
				t.String
				,
				message:
				t.String
				,
		})

	export type CommonCreateOrEditResponse = t.Static<typeof CommonCreateOrEditResponseSchema>;
  
  
  
  
	export const BatchFilterRequestSchema = t.Record({
				page:
				t.Number
				,
				limit:
				t.Number
				,
				createdBy:
				t.Array(t.String)
				.Or(t.Null).Or(t.Undefined),
				batchId:
				t.Array(t.String)
				.Or(t.Null).Or(t.Undefined),
		})

	export type BatchFilterRequest = t.Static<typeof BatchFilterRequestSchema>;
  
  
  
  
	export const GetBatchByIDFilterRequestSchema = t.Record({
				batchId:
				t.String
				,
		})

	export type GetBatchByIDFilterRequest = t.Static<typeof GetBatchByIDFilterRequestSchema>;
  
  
  
  
	export const CreateBatchReviewRequestSchema = t.Record({
				batchId:
				t.String
				,
				comment:
				t.String
				,
				action:
				ProposalActionSchema
				,
		})

	export type CreateBatchReviewRequest = t.Static<typeof CreateBatchReviewRequestSchema>;
  
  
  
  
	export const GetAllEventRequestSchema = t.Record({
				page:
				t.Number
				,
				limit:
				t.Number
				,
				eventName:
				t.String
				.Or(t.Null).Or(t.Undefined),
				productName:
				t.String
				.Or(t.Null).Or(t.Undefined),
				featureName:
				t.String
				.Or(t.Null).Or(t.Undefined),
				eventStatus:
				ProposalStatusSchema
				.Or(t.Null).Or(t.Undefined),
				createdBy:
				t.String
				.Or(t.Null).Or(t.Undefined),
				isLegacy:
				FilterLegacyMappingSchema
				.Or(t.Null).Or(t.Undefined),
		})

	export type GetAllEventRequest = t.Static<typeof GetAllEventRequestSchema>;
  
  
  
  
	export const GetEventByIdRequestSchema = t.Record({
				eventId:
				t.String
				,
		})

	export type GetEventByIdRequest = t.Static<typeof GetEventByIdRequestSchema>;
  
  
  
  
	export const GetProposalByIdRequestSchema = t.Record({
				proposalId:
				t.String
				,
		})

	export type GetProposalByIdRequest = t.Static<typeof GetProposalByIdRequestSchema>;
  
  
  
  
	export const CreateEventRequestSchema = t.Record({
				name:
				t.String
				,
				description:
				t.String
				,
				eventOwners:
				t.Array(t.String)
				,
				eventTags:
				t.Array(t.String)
				,
				screnshotUrls:
				t.Array(t.String)
				.Or(t.Null).Or(t.Undefined),
				eventId:
				t.String
				.Or(t.Null).Or(t.Undefined),
				thumbnail:
				t.String
				,
				isLearning:
				IsLearningSchema
				,
				productId:
				t.String
				,
				featureId:
				t.String
				,
		})

	export type CreateEventRequest = t.Static<typeof CreateEventRequestSchema>;
  
  
  
  
	export const PaginationRequestSchema = t.Record({
				page:
				t.Number
				,
				limit:
				t.Number
				,
		})

	export type PaginationRequest = t.Static<typeof PaginationRequestSchema>;
  
  
  
  
	export const CreateProposalRequestSchema = t.Record({
				eventId:
				t.String
				,
				proposalId:
				t.String
				.Or(t.Null).Or(t.Undefined),
				schema:
				t.String
				,
				reviewers:
				t.Array(t.String)
				,
		})

	export type CreateProposalRequest = t.Static<typeof CreateProposalRequestSchema>;
  
  
  
  
	export const GetReviewersByProductIDsRequestSchema = t.Record({
				productIds:
				t.Array(t.String)
				,
		})

	export type GetReviewersByProductIDsRequest = t.Static<typeof GetReviewersByProductIDsRequestSchema>;
  
  
  
  
	export const GetAutocompleteRequestSchema = t.Record({
				param:
				AutocompleteParamSchema
				,
				input:
				t.String
				,
		})

	export type GetAutocompleteRequest = t.Static<typeof GetAutocompleteRequestSchema>;
  
  
  
  
	export const DeployProposalRequestSchema = t.Record({
				proposalId:
				t.String
				,
				deployStage:
				DeployVariantSchema
				,
		})

	export type DeployProposalRequest = t.Static<typeof DeployProposalRequestSchema>;
  
  
  
  
	export const DeployProposalsRequestSchema = t.Record({
				proposalIds:
				t.Array(t.String)
				,
		})

	export type DeployProposalsRequest = t.Static<typeof DeployProposalsRequestSchema>;
  
  
  
  
	export const ReviewProposalRequestSchema = t.Record({
				proposalId:
				t.String
				,
				reviewComment:
				t.String
				,
				action:
				ProposalActionSchema
				,
		})

	export type ReviewProposalRequest = t.Static<typeof ReviewProposalRequestSchema>;
  
  
  
  
	export const RebuildSchemaRequestSchema = t.Record({
				buildId:
				t.String
				,
		})

	export type RebuildSchemaRequest = t.Static<typeof RebuildSchemaRequestSchema>;
  
  
  
  
	export const GetDeploymentStatusRequestSchema = t.Record({
				proposalIds:
				t.Array(t.String)
				,
		})

	export type GetDeploymentStatusRequest = t.Static<typeof GetDeploymentStatusRequestSchema>;
  
  
  
  
	export const GetDetailTrackingGroupRequestSchema = t.Record({
				product:
				t.String
				,
				feature:
				t.String
				,
				status:
				ProposalStatusSchema
				,
		})

	export type GetDetailTrackingGroupRequest = t.Static<typeof GetDetailTrackingGroupRequestSchema>;
  
  
  
  
	export const GetEventDataRequestSchema = t.Record({
				eventNames:
				t.String
				,
				startDate:
				t.String
				,
				endDate:
				t.String
				,
				limit:
				t.String
				,
				memberSerials:
				t.Array(t.String)
				.Or(t.Null).Or(t.Undefined),
				uaClients:
				t.Array(t.String)
				.Or(t.Null).Or(t.Undefined),
				schemaType:
				SchemaTypeSchema
				,
				deployStage:
				DeployVariantSchema
				,
				country:
				CountrySchema
				,
		})

	export type GetEventDataRequest = t.Static<typeof GetEventDataRequestSchema>;
  
  
  
  
	export const GetEvenDataReponseSchema = t.Record({
				schemaData:
				t.String
				,
		})

	export type GetEvenDataReponse = t.Static<typeof GetEvenDataReponseSchema>;
  
  
  
  
	export const ProposalCommentRequestSchema = t.Record({
				id:
				t.String
				,
				page:
				t.Number
				,
				limit:
				t.Number
				,
		})

	export type ProposalCommentRequest = t.Static<typeof ProposalCommentRequestSchema>;
  
  
  
  
	export const PostCommentSchema = t.Record({
				proposalId:
				t.String
				,
				comment:
				t.String
				,
		})

	export type PostComment = t.Static<typeof PostCommentSchema>;
  
  
  
  
	export const GetBuildByProposalIdRequestSchema = t.Record({
				proposalId:
				t.String
				,
				deployTo:
				DeployVariantFilterSchema
				.Or(t.Null).Or(t.Undefined),
		})

	export type GetBuildByProposalIdRequest = t.Static<typeof GetBuildByProposalIdRequestSchema>;
  
  
  
  
	export const CloseProposalRequestSchema = t.Record({
				proposalId:
				t.String
				,
		})

	export type CloseProposalRequest = t.Static<typeof CloseProposalRequestSchema>;
  
  
  
  
	export const CreateFeatureRequestSchema = t.Record({
				featiureName:
				t.String
				,
		})

	export type CreateFeatureRequest = t.Static<typeof CreateFeatureRequestSchema>;
  
  
  
  
	export const CreateProductRequestSchema = t.Record({
				productName:
				t.String
				,
		})

	export type CreateProductRequest = t.Static<typeof CreateProductRequestSchema>;
  
  
  
  
	export const CreateAsynqTaskRequestSchema = t.Record({
				proposalId:
				t.Array(t.String)
				,
		})

	export type CreateAsynqTaskRequest = t.Static<typeof CreateAsynqTaskRequestSchema>;
  
  
  
  
	export const ETLConfigGeneratorConfigSchema = t.Record({
				description:
				t.String
				,
				partitioned:
				t.Boolean
				,
				destinationPartitionOffset:
				t.Number
				,
				schedule:
				t.String
				,
				sla:
				t.Number
				,
				country:
				t.Array(t.String)
				,
		})

	export type ETLConfigGeneratorConfig = t.Static<typeof ETLConfigGeneratorConfigSchema>;
  
  
  
  
	export const ETLConfigGeneratorCheckerSqlSchema = t.Record({
				type:
				t.String
				,
		})

	export type ETLConfigGeneratorCheckerSql = t.Static<typeof ETLConfigGeneratorCheckerSqlSchema>;
  
  
  
  
	export const SchemaRequestSchema = t.Record({
				name:
				t.String
				,
				description:
				t.String
				,
				isLearning:
				IsLearningSchema
				,
				productId:
				t.String
				,
				featureId:
				t.String
				,
				thumbnail:
				t.String
				,
				images:
				t.Array(t.String)
				,
				owners:
				t.Array(t.String)
				,
				schema:
				t.String
				,
				reviewers:
				t.Array(t.String)
				,
		})

	export type SchemaRequest = t.Static<typeof SchemaRequestSchema>;
  
  
  
  
	export const BuildDetailSchema = t.Record({
				buildId:
				t.String
				,
				logUrl:
				t.String
				,
				lastCheckedStatus:
				LastCheckedStatusSchema
				,
				deployTo:
				DeployVariantSchema
				,
				startedAt:
				t.String
				,
				lastCheckedAt:
				t.String
				,
				type:
				DeploymentTypeSchema
				,
		})

	export type BuildDetail = t.Static<typeof BuildDetailSchema>;
  
  
  
  
	export const ReviewDetailSchema = t.Record({
				reviewId:
				t.String
				,
				comment:
				t.String
				,
				emailReviewer:
				t.String
				,
				action:
				ProposalActionSchema
				,
				createdAt:
				t.String
				,
		})

	export type ReviewDetail = t.Static<typeof ReviewDetailSchema>;
  
  
  
  
	export const PaginationSchema = t.Record({
				page:
				t.Number
				,
				limit:
				t.Number
				,
				totalData:
				t.Number
				,
				totalPage:
				t.Number
				,
		})

	export type Pagination = t.Static<typeof PaginationSchema>;
  
  
  
  
	export const MetaDataHistorySchema = t.Record({
				createdBy:
				t.String
				,
				updatedBy:
				t.String
				,
				createdAt:
				t.String
				,
				updatedAt:
				t.String
				,
		})

	export type MetaDataHistory = t.Static<typeof MetaDataHistorySchema>;
  
  
  
  
	export const EventTagSchema = t.Record({
				id:
				t.String
				,
				name:
				t.String
				,
		})

	export type EventTag = t.Static<typeof EventTagSchema>;
  
  
  
  
	export const ReviewersSchema = t.Record({
				id:
				t.String
				,
				email:
				t.String
				,
				tagId:
				t.String
				,
		})

	export type Reviewers = t.Static<typeof ReviewersSchema>;
  
  
  
  
	export const AutoCompleteItemSchema = t.Record({
				value:
				t.String
				,
				label:
				t.String
				,
		})

	export type AutoCompleteItem = t.Static<typeof AutoCompleteItemSchema>;
  
  
  
  
	export const TrackingGroupSchema = t.Record({
				group:
				t.String
				,
				productName:
				t.String
				,
				featureName:
				t.String
				,
				productId:
				t.String
				,
				featureId:
				t.String
				,
		})

	export type TrackingGroup = t.Static<typeof TrackingGroupSchema>;
  
  
  
  
	export const DetailTrackingActionsSchema = t.Record({
				name:
				t.String
				,
				status:
				ProposalStatusSchema
				,
				schema:
				t.String
				,
				description:
				t.String
				,
				proposalId:
				t.String
				,
				eventId:
				t.String
				,
				thumbnailUrl:
				t.String
				,
		})

	export type DetailTrackingActions = t.Static<typeof DetailTrackingActionsSchema>;
  
  
  
  
	export const CommentSchema = t.Record({
				comment:
				t.String
				,
				commentBy:
				t.String
				,
				commentTime:
				t.String
				,
		})

	export type Comment = t.Static<typeof CommentSchema>;
  
  
  
  
	export const ETLConfigGeneratorRequestSchema = t.Record({
				type:
				ETLConfigTypesSchema
				,
				email:
				t.String
				,
				project:
				t.String
				,
				query:
				t.String
				,
				previousTableName:
				t.String
				,
				config:
				ETLConfigGeneratorConfigSchema
				,
				checkerSql:
				t.Array(ETLConfigGeneratorCheckerSqlSchema)
				,
		})

	export type ETLConfigGeneratorRequest = t.Static<typeof ETLConfigGeneratorRequestSchema>;
  
  
  
  
	export const CreateSchemaRequestSchema = t.Record({
				name:
				t.String
				,
				schemaRequests:
				t.Array(SchemaRequestSchema)
				,
		})

	export type CreateSchemaRequest = t.Static<typeof CreateSchemaRequestSchema>;
  
  
  
  
	export const EventTrackingDetailSchema = t.Record({
				eventId:
				t.String
				,
				name:
				t.String
				,
				description:
				t.String
				,
				isLearning:
				IsLearningSchema
				,
				thumbnail:
				t.String
				,
				screenshots:
				t.Array(t.String)
				,
				owners:
				t.Array(t.String)
				,
				schema:
				t.String
				,
				reviewers:
				t.Array(t.String)
				,
				productId:
				t.String
				,
				productName:
				t.String
				,
				featureId:
				t.String
				,
				featureName:
				t.String
				,
				actionName:
				t.String
				,
				deployments:
				t.Array(BuildDetailSchema)
				,
				proposalId:
				t.String
				,
		})

	export type EventTrackingDetail = t.Static<typeof EventTrackingDetailSchema>;
  
  
  
  
	export const GetBuildByProposalIdResponseSchema = t.Record({
				buildDetails:
				t.Array(BuildDetailSchema)
				,
		})

	export type GetBuildByProposalIdResponse = t.Static<typeof GetBuildByProposalIdResponseSchema>;
  
  
  
  
	export const MetadataSchema = t.Record({
				pagination:
				PaginationSchema
				.Or(t.Null).Or(t.Undefined),
				metaDataHistory:
				MetaDataHistorySchema
				.Or(t.Null).Or(t.Undefined),
		})

	export type Metadata = t.Static<typeof MetadataSchema>;
  
  
  
  
	export const AutocompleteResponseSchema = t.Record({
				data:
				t.Array(AutoCompleteItemSchema)
				,
		})

	export type AutocompleteResponse = t.Static<typeof AutocompleteResponseSchema>;
  
  
  
  
	export const GetDetailTrackingGroupResponseSchema = t.Record({
				productName:
				t.String
				,
				featureName:
				t.String
				,
				actions:
				t.Array(DetailTrackingActionsSchema)
				,
		})

	export type GetDetailTrackingGroupResponse = t.Static<typeof GetDetailTrackingGroupResponseSchema>;
  
  
  
  
	export const BatchDetailSchema = t.Record({
				batchId:
				t.String
				,
				name:
				t.String
				,
				eventTrackings:
				t.Array(EventTrackingDetailSchema)
				,
				batchStatus:
				BatchStatusSchema
				,
				reviews:
				t.Array(ReviewDetailSchema)
				,
				createdBy:
				t.String
				,
				createdAt:
				t.String
				,
		})

	export type BatchDetail = t.Static<typeof BatchDetailSchema>;
  
  
  
  
	export const GetReviewersByProductIDsResponseSchema = t.Record({
				reviewers:
				t.Array(ReviewersSchema)
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetReviewersByProductIDsResponse = t.Static<typeof GetReviewersByProductIDsResponseSchema>;
  
  
  
  
	export const ProposalSchema = t.Record({
				id:
				t.String
				,
				eventId:
				t.String
				,
				schema:
				t.String
				,
				status:
				ProposalStatusSchema
				,
				eventName:
				t.String
				,
				reviewers:
				t.Array(t.String)
				,
				Metadata:
				MetadataSchema
				.Or(t.Null).Or(t.Undefined),
		})

	export type Proposal = t.Static<typeof ProposalSchema>;
  
  
  
  
	export const ProposalCommentResponseSchema = t.Record({
				comments:
				t.Array(CommentSchema)
				,
				metadata:
				MetadataSchema
				,
		})

	export type ProposalCommentResponse = t.Static<typeof ProposalCommentResponseSchema>;
  
  
  
  
	export const GetTrackingGroupResponseSchema = t.Record({
				groups:
				t.Array(TrackingGroupSchema)
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetTrackingGroupResponse = t.Static<typeof GetTrackingGroupResponseSchema>;
  
  
  
  
	export const GetBatchesResponseSchema = t.Record({
				batches:
				t.Array(BatchDetailSchema)
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetBatchesResponse = t.Static<typeof GetBatchesResponseSchema>;
  
  
  
  
	export const GetBatchByIDResponseSchema = t.Record({
				batch:
				BatchDetailSchema
				,
		})

	export type GetBatchByIDResponse = t.Static<typeof GetBatchByIDResponseSchema>;
  
  
  
  
	export const GetProposalByIdResponseSchema = t.Record({
				proposal:
				ProposalSchema
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetProposalByIdResponse = t.Static<typeof GetProposalByIdResponseSchema>;
  
  
  
  
	export const EventSchema = t.Record({
				id:
				t.String
				,
				name:
				t.String
				,
				schema:
				t.String
				,
				description:
				t.String
				,
				metadata:
				MetadataSchema
				.Or(t.Null).Or(t.Undefined),
				eventTags:
				t.Array(EventTagSchema)
				,
				eventProposals:
				t.Array(ProposalSchema)
				.Or(t.Null).Or(t.Undefined),
				eventOwners:
				t.Array(t.String)
				,
				screenshotUrls:
				t.Array(t.String)
				,
				thumbnail:
				t.String
				,
				isLearning:
				IsLearningSchema
				,
				productId:
				t.String
				,
				featureId:
				t.String
				,
				latestProposalStatus:
				ProposalStatusSchema
				,
				isLegacy:
				t.Boolean
				,
		})

	export type Event = t.Static<typeof EventSchema>;
  
  
  
  
	export const GetAllProposalsResponseSchema = t.Record({
				proposals:
				t.Array(ProposalSchema)
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetAllProposalsResponse = t.Static<typeof GetAllProposalsResponseSchema>;
  
  
  
  
	export const GetAllEventResponseSchema = t.Record({
				events:
				t.Array(EventSchema)
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetAllEventResponse = t.Static<typeof GetAllEventResponseSchema>;
  
  
  
  
	export const GetEventByIdResponseSchema = t.Record({
				event:
				EventSchema
				,
				metadata:
				MetadataSchema
				,
		})

	export type GetEventByIdResponse = t.Static<typeof GetEventByIdResponseSchema>;
  
	
		
		
		
		

		export type GetETLConfigGeneratorError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getETLConfigGeneratorExn (
			 payload: GetETLConfigGeneratorRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetETLConfigGeneratorResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/get_etl_config/${payload.uuid}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getETLConfigGenerator (
			 payload: GetETLConfigGeneratorRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetETLConfigGeneratorResponseSchema;
				
			
			return new Promise<
				Either<
					GetETLConfigGeneratorError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/get_etl_config/${payload.uuid}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type ETLConfigGeneratorError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST_INVALID_ETL_CONFIG": { error: APIErrorResponse };
				"BAD_REQUEST_INVALID_USER_TOKEN": { error: APIErrorResponse };
		}>;

		export function eTLConfigGeneratorExn (
			 payload: ETLConfigGeneratorRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = ETLConfigGeneratorResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/etl_config_generator`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST_INVALID_ETL_CONFIG", 
										error,
									});
								case 91103: 
									return reject({
										_tag: "BAD_REQUEST_INVALID_USER_TOKEN", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function eTLConfigGenerator (
			 payload: ETLConfigGeneratorRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = ETLConfigGeneratorResponseSchema;
				
			
			return new Promise<
				Either<
					ETLConfigGeneratorError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/etl_config_generator`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST_INVALID_ETL_CONFIG", error})
									);
								case 91103: 
									return resolve(
										left({ _tag: "BAD_REQUEST_INVALID_USER_TOKEN", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateSchemaReqError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function createSchemaReqExn (
			 payload: CreateSchemaRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/schema_request`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createSchemaReq (
			 payload: CreateSchemaRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateSchemaReqError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/schema_request`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetBatchesError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getBatchesExn (
			 payload: BatchFilterRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetBatchesResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/schema_request`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getBatches (
			 payload: BatchFilterRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetBatchesResponseSchema;
				
			
			return new Promise<
				Either<
					GetBatchesError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/schema_request`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetBatchByIDError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getBatchByIDExn (
			 payload: GetBatchByIDFilterRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetBatchByIDResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/schema_request/detail/${payload.batchId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getBatchByID (
			 payload: GetBatchByIDFilterRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetBatchByIDResponseSchema;
				
			
			return new Promise<
				Either<
					GetBatchByIDError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/schema_request/detail/${payload.batchId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateBatchReviewError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function createBatchReviewExn (
			 payload: CreateBatchReviewRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/batch_review`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createBatchReview (
			 payload: CreateBatchReviewRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateBatchReviewError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/batch_review`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetAllEventError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getAllEventExn (
			 payload: GetAllEventRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetAllEventResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getAllEvent (
			 payload: GetAllEventRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetAllEventResponseSchema;
				
			
			return new Promise<
				Either<
					GetAllEventError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetEventByIdError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
				"NOT_FOUND": { error: APIErrorResponse };
		}>;

		export function getEventByIdExn (
			 payload: GetEventByIdRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetEventByIdResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event/${payload.eventId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								case 91103: 
									return reject({
										_tag: "NOT_FOUND", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getEventById (
			 payload: GetEventByIdRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetEventByIdResponseSchema;
				
			
			return new Promise<
				Either<
					GetEventByIdError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event/${payload.eventId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
								case 91103: 
									return resolve(
										left({ _tag: "NOT_FOUND", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetProposalByIdError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
				"NOT_FOUND": { error: APIErrorResponse };
		}>;

		export function getProposalByIdExn (
			 payload: GetProposalByIdRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetProposalByIdResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/${payload.proposalId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								case 91103: 
									return reject({
										_tag: "NOT_FOUND", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getProposalById (
			 payload: GetProposalByIdRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetProposalByIdResponseSchema;
				
			
			return new Promise<
				Either<
					GetProposalByIdError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/${payload.proposalId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
								case 91103: 
									return resolve(
										left({ _tag: "NOT_FOUND", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateEventError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
				"ALREADY_EXIST": { error: APIErrorResponse };
		}>;

		export function createEventExn (
			 payload: CreateEventRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								case 91104: 
									return reject({
										_tag: "ALREADY_EXIST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createEvent (
			 payload: CreateEventRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateEventError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
								case 91104: 
									return resolve(
										left({ _tag: "ALREADY_EXIST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type EditEventByIdError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
				"ALREADY_EXIST": { error: APIErrorResponse };
		}>;

		export function editEventByIdExn (
			 payload: CreateEventRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event`,
					method: "put",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								case 91104: 
									return reject({
										_tag: "ALREADY_EXIST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function editEventById (
			 payload: CreateEventRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					EditEventByIdError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/event`,
					method: "put",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
								case 91104: 
									return resolve(
										left({ _tag: "ALREADY_EXIST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetAllProposalError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getAllProposalExn (
			 payload: PaginationRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetAllProposalsResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposals`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getAllProposal (
			 payload: PaginationRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetAllProposalsResponseSchema;
				
			
			return new Promise<
				Either<
					GetAllProposalError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposals`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateProposalError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function createProposalExn (
			 payload: CreateProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createProposal (
			 payload: CreateProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateProposalError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type EditProposalError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function editProposalExn (
			 payload: CreateProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal`,
					method: "put",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function editProposal (
			 payload: CreateProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					EditProposalError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal`,
					method: "put",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetReviewersByProductIDsError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getReviewersByProductIDsExn (
			 payload: GetReviewersByProductIDsRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetReviewersByProductIDsResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/reviewers`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getReviewersByProductIDs (
			 payload: GetReviewersByProductIDsRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetReviewersByProductIDsResponseSchema;
				
			
			return new Promise<
				Either<
					GetReviewersByProductIDsError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/reviewers`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetAutocompleteError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getAutocompleteExn (
			 payload: GetAutocompleteRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = AutocompleteResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/autocomplete/param/${payload.param}/input/${payload.input}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getAutocomplete (
			 payload: GetAutocompleteRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = AutocompleteResponseSchema;
				
			
			return new Promise<
				Either<
					GetAutocompleteError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/autocomplete/param/${payload.param}/input/${payload.input}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type DeployProposalError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function deployProposalExn (
			 payload: DeployProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/deploy/${payload.proposalId}`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function deployProposal (
			 payload: DeployProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					DeployProposalError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/deploy/${payload.proposalId}`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type DeployProposalsError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function deployProposalsExn (
			 payload: DeployProposalsRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/batch/deploy`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function deployProposals (
			 payload: DeployProposalsRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					DeployProposalsError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/batch/deploy`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type ReviewProposalStagingError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function reviewProposalStagingExn (
			 payload: ReviewProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/reviewProposal/staging`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function reviewProposalStaging (
			 payload: ReviewProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					ReviewProposalStagingError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/reviewProposal/staging`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type ReviewProposalProductionError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function reviewProposalProductionExn (
			 payload: ReviewProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/reviewProposal/production`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function reviewProposalProduction (
			 payload: ReviewProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					ReviewProposalProductionError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/reviewProposal/production`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type RebuildSchemaError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function rebuildSchemaExn (
			 payload: RebuildSchemaRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/rebuild/${payload.buildId}`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function rebuildSchema (
			 payload: RebuildSchemaRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					RebuildSchemaError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/rebuild/${payload.buildId}`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetDeploymentStatusError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getDeploymentStatusExn (
			 payload: GetDeploymentStatusRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/cloud_build/status`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getDeploymentStatus (
			 payload: GetDeploymentStatusRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					GetDeploymentStatusError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/cloud_build/status`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetTrackingGroupsError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getTrackingGroupsExn (
			 payload: PaginationRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetTrackingGroupResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/tracking/groups`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getTrackingGroups (
			 payload: PaginationRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetTrackingGroupResponseSchema;
				
			
			return new Promise<
				Either<
					GetTrackingGroupsError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/tracking/groups`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetDetailTrackingGroupError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getDetailTrackingGroupExn (
			 payload: GetDetailTrackingGroupRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetDetailTrackingGroupResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/tracking/groups/detail`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getDetailTrackingGroup (
			 payload: GetDetailTrackingGroupRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetDetailTrackingGroupResponseSchema;
				
			
			return new Promise<
				Either<
					GetDetailTrackingGroupError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/tracking/groups/detail`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
	
		
		
		
		

		export type GetEventDataByEventNameError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getEventDataByEventNameExn (
			 payload: GetEventDataRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetEvenDataReponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/dataviewer`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getEventDataByEventName (
			 payload: GetEventDataRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetEvenDataReponseSchema;
				
			
			return new Promise<
				Either<
					GetEventDataByEventNameError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/dataviewer`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetCommentByProposalIdError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getCommentByProposalIdExn (
			 payload: ProposalCommentRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = ProposalCommentResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/comments/${payload.id}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getCommentByProposalId (
			 payload: ProposalCommentRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = ProposalCommentResponseSchema;
				
			
			return new Promise<
				Either<
					GetCommentByProposalIdError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/comments/${payload.id}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type PostCommentByProposalIdError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function postCommentByProposalIdExn (
			 payload: PostComment, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/comments/${payload.proposalId}`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function postCommentByProposalId (
			 payload: PostComment, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					PostCommentByProposalIdError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/comments/${payload.proposalId}`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type GetBuildByProposalIdError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function getBuildByProposalIdExn (
			 payload: GetBuildByProposalIdRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetBuildByProposalIdResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/build/${payload.proposalId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function getBuildByProposalId (
			 payload: GetBuildByProposalIdRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = GetBuildByProposalIdResponseSchema;
				
			
			return new Promise<
				Either<
					GetBuildByProposalIdError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/build/${payload.proposalId}`,
					method: "get",
					withAuth:  true ,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CloseProposalError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function closeProposalExn (
			 payload: CloseProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/${payload.proposalId}`,
					method: "delete",
					withAuth:  true ,
					 
						data: payload,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function closeProposal (
			 payload: CloseProposalRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CloseProposalError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/proposal/${payload.proposalId}`,
					method: "delete",
					withAuth:  true ,
					 
						data: payload,
					
					 
						params: payload,
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateFeatureError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function createFeatureExn (
			 payload: CreateFeatureRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/feature`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createFeature (
			 payload: CreateFeatureRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateFeatureError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/feature`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateProductError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function createProductExn (
			 payload: CreateProductRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/product`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createProduct (
			 payload: CreateProductRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateProductError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/product`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	
	
		
		
		
		

		export type CreateAsynqTaskError = ADT<{
			DECODE_ERROR: { message: string };
			UNKNOWN_ERROR: { error?: ErrorResponse };
			SERVER_ERROR: { error: APIErrorResponse };
				"INTERNAL_ERROR": { error: APIErrorResponse };
				"BAD_REQUEST": { error: APIErrorResponse };
		}>;

		export function createAsynqTaskExn (
			 payload: CreateAsynqTaskRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<t.Static<typeof ResponseSchema>>((resolve, reject) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/asynq_task`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then((dataOk) => {
					resolve(dataOk);
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return reject({_tag: 'DECODE_ERROR', message: error.message});
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return reject({
										_tag: "INTERNAL_ERROR", 
										error,
									});
								case 91102: 
									return reject({
										_tag: "BAD_REQUEST", 
										error,
									});
								default:
									return reject({
										_tag: 'SERVER_ERROR',
										error,
									});
						}
					}
					return reject({_tag: 'UNKNOWN_ERROR', error});
				})
			);
		}

		export function createAsynqTask (
			 payload: CreateAsynqTaskRequest, 
			context?: FetcherContext,
		) {
			
				
					const ResponseSchema = CommonCreateOrEditResponseSchema;
				
			
			return new Promise<
				Either<
					CreateAsynqTaskError, 
					t.Static<typeof ResponseSchema>
				>
			>((resolve) => 
				fetcher<t.Static<typeof ResponseSchema>>({
					...context,
					url: `/api/v3/data_cms_api/asynq_task`,
					method: "post",
					withAuth:  true ,
					 
						data: payload,
					
					
				}).then(data => {
					// @ts-ignore
					if (!context?.disableTypeValidator) {
						
							return ResponseSchema.check(data);
						
					} else {
						return data;
					}
				}).then(dataOk => {
					resolve(right(dataOk));
				})
				.catch(error => {
					if (error instanceof t.ValidationError) {
						return resolve(
							left({ _tag: "DECODE_ERROR", message: error.message })
						);
					} else if (error && 'errorCode' in error) {
						switch (error.errorCode) {
								case 91101: 
									return resolve(
										left({ _tag: "INTERNAL_ERROR", error})
									);
								case 91102: 
									return resolve(
										left({ _tag: "BAD_REQUEST", error})
									);
							default:
								return resolve(
									left({ _tag: 'SERVER_ERROR', error })
								);
						}
					}
					return resolve(
						left({ _tag: "UNKNOWN_ERROR", error })
					);
				})
			);
		}
	

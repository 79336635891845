import { Box } from '@chakra-ui/layout';
import Head from 'next/head';

import { PageHeader } from './PageHeader';

type PageProps = {
  children: React.ReactElement;
};

function Page({ children }: PageProps) {
  return (
    <Box minH="100vh" p="36px" pt="16px">
      <Head>
        <title>RG Data Platform</title>
        <meta name="description" content="Generated by create next app" />
      </Head>
      <PageHeader />
      {children}
    </Box>
  );
}

export { Page };

import { browserCookie } from 'shared/cookie';
import { useColorMode } from '@chakra-ui/color-mode';
import { HStack } from '@chakra-ui/layout';
import { useRouter } from 'next/dist/client/router';
import NextLink from 'next/link';
import { Image } from '@chakra-ui/image';
import { icons } from 'shared/assets';
import { BsMoon } from '@react-icons/all-files/bs/BsMoon';
import { BsSun } from '@react-icons/all-files/bs/BsSun';
import { Button } from '@chakra-ui/react';

type Menu = {
  label: string;
  href: string;
  icon?: React.ReactElement;
};

const menuList: Array<Menu> = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Tracking Data Viewer',
    href: '/data-viewer',
  },
  {
    label: 'Create Feature or Produts',
    href: '/create-feature-or-product',
  },
  {
    label: 'ETL Generator',
    href: '/etl-generator',
  },
];

function PageHeader() {
  const router = useRouter();
  const isLoggedin = browserCookie.get('isLoggedIn');
  const onLogout = () => {
    const redirectUrl = encodeURIComponent(window.location.hostname);
    const logoutPath = router.basePath
      ? `${router.basePath}/logout`
      : '/logout';
    window.location.replace(`${logoutPath}?redirect_url=${redirectUrl}`);
  };
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack
      position="sticky"
      top="0px"
      pb="16px"
      zIndex="99"
      backgroundColor={colorMode !== 'light' ? 'gray.800' : 'white'}
      borderBottom="1px solid gray"
      justifyContent="space-between"
    >
      <HStack>
        <NextLink href="/" passHref prefetch={false}>
          <HStack as="a" title="Roboguru" spacing={3} mr={8}>
            <Image src={icons('rg-logo')} />
          </HStack>
        </NextLink>
        {menuList.map(({ label, href }) => (
          <NextLink key={label} href={href} passHref>
            <Button
              as={'a'}
              variant={router.pathname === href ? 'solid' : 'ghost'}
            >
              {label}
            </Button>
          </NextLink>
        ))}
      </HStack>

      <HStack>
        <Button onClick={toggleColorMode}>
          {colorMode === 'dark' ? <BsSun /> : <BsMoon />}
        </Button>
        {isLoggedin && <Button onClick={onLogout}>Logout</Button>}
      </HStack>
    </HStack>
  );
}

export { PageHeader };
